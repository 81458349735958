<template>
  <div>
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <div class="form-row mb-1 justify-content-between">
                <div class="col-lg-4 mb-1 mb-lg-0">
                  <label>Filter by {{ talentOrUser }}</label>
                  <v-select
                    v-model="filter.talent_uuid"
                    :options="users"
                    :reduce="option => option.uuid"
                    @search="onSearchUser"
                  >
                    <template slot="no-options">
                      Type {{ talentOrUser }} name...
                    </template>
                  </v-select>
                </div>
                <div class="col-lg-4 mb-1 mb-lg-0 align-self-end justify-content-end">
                  <input
                    v-model="filter.keyword"
                    type="text"
                    placeholder="Search"
                    class="form-control form-control-sm float-right"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Total</th>
            <th>Status</th>
            <th>Bank Account</th>
            <th>Time</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              <router-link :to="`/${talentOrUser}-detail/${item.holder.uuid}`">
                {{ item.holder.name }}
              </router-link>
            </td>
            <td>
              {{ item.amount | formatAmount }}
            </td>
            <td>{{ item.total | formatAmount }}</td>
            <td>
              <span
                class="badge text-capitalize"
                :class="item.status == 'pending' ? 'badge-light-warning' : item.status == 'failed' ? 'badge-light-danger' : 'badge-light-success'"
              >
                {{ item.status }}
              </span>
            </td>
            <td>
              <span v-if="item.bank_account">
                {{ item.bank_account.account_name }}<br><small>{{ item.bank_account.bank_name }} | {{ item.bank_account.account_number }} </small>
              </span>
              <span v-else>-</span>
            </td>
            <td>{{ item.time | formatDateRegister }}</td>
            <td>
              <WalletAction
                :is-loading-button="isLoadingComponent"
                :get-data="getWithdrawData"
                :type-action="'Withdraw'"
                :slug="'withdraw'"
                :link="link"
                :item="item"
                :typeWallet="typeWithdraw"
                @loading="loading"
              />
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getWithdrawData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import WalletAction from '@/components/Wallet/WalletAction.vue'

export default {
  components: {
    BSpinner,
    WalletAction,
    vSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    getWithdrawData: {
      type: Function,
    },
    typeTopup: {
      type: String,
    },
    typeWithdraw: {
      type: String,
    },
    link: {
      type: String,
    },
    talentOrUser: {
      type: String,
    },
    endpointTalentOrUser: {
      type: String,
    },
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
      users: [],
      filter: {
        talent_uuid: '',
        keyword: '',
      },
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
    filter: {
      handler(value) {
        this.$emit('filter', value)
      },
      deep: true,
    },
  },
  methods: {
    loading(value) {
      this.isLoadingComponent = value
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(loading, search, this)
      }
    },
    searchUser: _.debounce((loading, search, vm) => {
      vm.$http.get(`/admin/${vm.endpointTalentOrUser}?type=normal&per_page=10&keyword=${escape(search)}`)
        .then(response => {
          vm.users = []
          response.data.data.data.forEach(user => {
            vm.users.push({
              uuid: user.uuid,
              label: user.name,
            })
          })
          loading(false)
        })
    }, 300),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
